import React from 'react'
import Main from './main/Main'

function App() {
    return (
        <Main />
    )
}

export default App
